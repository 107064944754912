/**
 * Crunch Button Template Part Webpack
 */

import * as React from "react";
import PropTypes from "prop-types";
import "./_crunch-button-styles.scss";
import { Link } from "gatsby";

const Button = ({ link, buttonText }) => {
    return (
        <Link
            to={link}
            className="crunch-button crunch-button__full-background crunch-button__full-background crunch-button__full-background--primary-color crunch-button__full-background--large link-white fw-semibold"
        >
            {buttonText}
            <svg
                width="25"
                height="10"
                viewBox="0 0 25 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect y="3.85715" width="22.8571" height="2.14286" fill="white" />
                <path d="M25 5L19.6429 9.33013V0.669873L25 5Z" fill="white" />
            </svg>
        </Link>
    );
};

Button.defaultProps = {
    link: "#",
    buttonText: "button text",
};

Button.propTypes = {
    buttonText: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default Button;
