import * as React from "react";
import Layout from "../../layouts";
import Button from "@partials/button";
import ImageBlock from "@partials/image-block/image-block";
import "./_gallery_styles.scss";
import QuoteSection from "@partials/quote-section/quote-section";
import { quotes, positions } from "../../../pageData";

import RussellDrisch_SSGoodFortune from "@images/galleries/holidays/RussellDrisch_SSGoodFortune.png";
import CarolAnthony_Peace from "@images/galleries/holidays/CarolAnthony_Peace.png";
import CarolWald_AChildsChristmas from "@images/galleries/holidays/CarolWald_AChildsChristmas.png";
import ChristopherHewat_WithLove from "@images/galleries/holidays/ChristopherHewat_WithLove.png";
import FannyBrennan_TheTree from "@images/galleries/holidays/FannyBrennan_TheTree.png";
import RobertKulicke_Hope from "@images/galleries/holidays/RobertKulicke_Hope.png";

export default function SeriesOne() {
    return (
        <Layout pageTitle="Series I">
            <section className="gallery-hero-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row c-mb-only-8">
                        <div className="col-lg-7">
                            <h1 className="c-mb-only-2">Holiday Cards</h1>
                        </div>
                        <div className="col-lg-7">
                            <p className="c-mb-only-3">
                                Based on the popularity of the Artists’ Postcards Series I and II editions, select artists from the earlier series created special cards for the 1979 holiday season.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Russell Drisch"
                                image={RussellDrisch_SSGoodFortune}
                                imageX2={RussellDrisch_SSGoodFortune}
                                caption="S.S. Good Fortune"
                                alt="small, white boat with christmas trees on the edges"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Carol Anthony"
                                image={CarolAnthony_Peace}
                                imageX2={CarolAnthony_Peace}
                                caption="Peace"
                                alt="Peace"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Carol Wald"
                                image={CarolWald_AChildsChristmas}
                                imageX2={CarolWald_AChildsChristmas}
                                caption="A Child's Christmas"
                                alt="A Child's Christmas"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteI} mt0 />
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Christopher Hewat"
                                image={ChristopherHewat_WithLove}
                                imageX2={ChristopherHewat_WithLove}
                                alt="With Love"
                                caption="With Love"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Fanny Brennan"
                                image={FannyBrennan_TheTree}
                                imageX2={FannyBrennan_TheTree}
                                caption="The Tree"
                                alt="The Tree"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Robert Kulicke"
                                image={RobertKulicke_Hope}
                                imageX2={RobertKulicke_Hope}
                                alt="Hope"
                                caption="Hope"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="gallery-intro-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="text-primary fz-50 lh-1-3">Series I</h2>
                        </div>
                        <div className="col-lg-8">
                            <p className="c-mb-only-5 fz-24 lh-1-5 fw-light">
                                The initial edition of thirty-nine postcards was the first of its kind to be published in the U.S.A. Distinct from art postcards that are miniature versions of celebrated works, each Artists’ Postcard carries a new picture created especially for it by a living artist.
                            </p>
                            <Button link="/gallery/series-I" buttonText="Explore Series I" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="gallery-intro-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="text-primary fz-50 lh-1-4">Series II</h2>
                        </div>
                        <div className="col-lg-8">
                            <p className="c-mb-only-5 fz-24 lh-1-5 fw-light">
                                Series II consists of fifty works in varied media, produced originally on more than ten different papers, by diverse printing processes. The artists are painters, sculptors, photographers, poets, cartoonists, writers, filmmakers, architects, and a dancer.
                            </p>
                            <Button link="/gallery/series-II" buttonText="Explore Series II" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
