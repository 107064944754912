import React from "react";
import PropTypes from "prop-types";
import "./_quote-section.scss";
import IcoQuote from "@images/svg/ico-quote.svg";
export default function QuoteSection({ data, mt0 }) {
    return (
        <blockquote
            className={`quote-section position-relative overflow-hidden c-ml-0 c-pl-0 ${
                mt0 ? "mt-0" : ""
            }`}
        >
            <div className="container">
                <div className="row text-center">
                    <img
                        src={IcoQuote}
                        alt="samll red quote icon"
                        className="quote-icon mx-auto d-block"
                    />
                    <div className="quote">
                        <p className="lh-1-5 fz-24 fw-light">{data.quote1}</p>
                        {data.quote2 && (
                            <p className="quote lh-1-5 fz-24 fw-light">{data.quote2}</p>
                        )}
                    </div>
                    <cite className="fst-normal text-primary lh-2 fz-32">{data.author}</cite>
                    <p className="fst-italic mb-1 position lh-1-4 fz-18 opacity-65 text-black">{data.position}</p>
                    <p className="mb-1 placeAndDate lh-1-4 opacity-65 text-black">{data.placeAndDate}</p>
                </div>
            </div>
        </blockquote>
    );
}

QuoteSection.propTypes = {
    data: PropTypes.object.isRequired,
    mt0: PropTypes.bool,
};
